import Swiper from "swiper/bundle";
import { gsap, Back, Power4, ScrollTrigger } from "gsap/dist/all";

gsap.registerPlugin(Back, Power4, ScrollTrigger);

const roomRoadEle = ".js-room-road";

$(function () {
  if ($(roomRoadEle).length) {
    gsapAnimation();
  }

  //スライダー
  var swiper = new Swiper(".js-rooms-ceo-swiper", {
    loop: true,
    spaceBetween: 40,
    speed: 1200,
    effect: "fade",
    autoHeight: "true",

    fadeEffect: {
      crossFade: true
    },

    navigation: {
      nextEl: ".js-swiper-button-next",
      prevEl: ".js-swiper-button-prev"
    }
  });

  //スライダー切り替わり時スクロール位置を戻す
  swiper.on("slideChange", function () {
    $(".js-famous-quote-slider").animate(
      {
        scrollTop: 0
      },
      1200,
      "quart"
    );
  });

  //モーダル閉じた時にスライドを最初に戻す
  $(document).on("closed", ".remodal-famouse-quote", function () {
    swiper.slideToLoop(0);
    swiper.update();
  });
});

//読み込み、リサイズ時
$(window).on("load resize", function () {
  if ($(roomRoadEle).length) {
    var windowWidth = $(window).width();
    //SP時のみアドレスバー表示/非表示の切り替え禁止
    if (windowWidth < 768) {
      ScrollTrigger.normalizeScroll({ allowNestedScroll: true });
    }
  }
});

//スクロール時
$(window).scroll(function () {
  if ($(roomRoadEle).length) {
    pageTop();
  }
});

//==========
//ページトップボタン 表示
//==========
function pageTop() {
  var scrollTop = $(window).scrollTop(),
    contentsTop = Math.floor($(".js-road-start").offset().top), //小数点切り捨て
    pageTop = $(".js-page-top");

  //スクロールしたらページトップへを表示
  if (scrollTop >= contentsTop) {
    pageTop.addClass("is-fadein");
  } else {
    pageTop.removeClass("is-fadein");
  }
}

//==========
//gsap アニメーション
//==========
function gsapAnimation() {
  //道
  // gsap.to(".js-room-road", {
  //   height: "100%",
  //   duration: 1.1,
  //   ease : Power4.easeOut,
  //   scrollTrigger: {
  //     start: 'top 50%',
  //     trigger: ".js-room-road",
  //   },
  // });

  //道の上のオブジェクトなど
  gsap.utils.toArray(".js-room-road").forEach((el) => {
    gsap.to(el, {
      scrollTrigger: {
        start: "top 50%",
        trigger: el,
        toggleClass: "is-visible",
        once: true
      }
    });
  });

  //==========
  // 導入アニメーション
  //==========
  var roomStartAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: ".js-room-start-block",
      toggleActions: "play none restart none",
      start: "center bottom",
      end: "bottom bottom"
    }
  });

  roomStartAnimation.to(".js-room-start", {
    scale: 1,
    duration: 0.35,
    ease: Back.easeOut
  });

  //左右の装飾
  roomStartAnimation.to(
    ".js-start-bg",
    {
      opacity: 0,
      duration: 0.4,
      ease: Power4.easeOut
    },
    "<0.7"
  );

  //会長/社長アイコン
  roomStartAnimation.to(
    ".js-start-icon",
    {
      y: -10,
      duration: 0.3,
      repeat: -1,
      yoyo: true
    },
    "<0.4"
  );

  //クリックで消える
  $(".js-room-start").on("click", function () {
    var roomCLickStartAnimation = gsap.to(".js-room-start", {
      scale: 0,
      duration: 1,
      ease: Power4.easeOut
    });

    roomCLickStartAnimation.restart();
  });
}
