$(function () {
  // ================
  // トグル
  // ================
  $(".js-toggle").on("click", function () {
    if ($(this).hasClass("is-open")) {
      $(this).removeClass("is-open");
      $(this).next().slideUp();
    } else {
      $(this).addClass("is-open");
      $(this).next().slideDown();
    }
  });
});
