// Images
require.context("@/images/front", true);
// CSS
import "smartphoto/css/smartphoto.css";
import "swiper/swiper-bundle.css";
import "select2/dist/css/select2.css";
import "@/stylesheets/front/style.scss";
// JS
import "@/src/front/common";
import "@/src/front/museum-title";
import "@/src/front/museum-detail";
import "@/src/front/museum-history";
import "@/src/front/room";
import "@/src/front/inquiry";
import "@/src/front/game-top";
import "@/src/front/game-detail";
import "@/src/front/push-data-layer";
import "@/src/front/help";
import "@/src/front/question-v1";
import "@/src/front/goods_factory";
import "@/src/front/winners";
import "@/controllers/front";
