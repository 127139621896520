import Swiper from "swiper/bundle";
import { screenLock, screenUnlock } from "./common";

let swiper = null;

$(window).on("load resize", function () {
  var w = $(window).width();
  var slideLength = $(".js-swiper-detail .swiper-slide").length;

  //本体スライダー切り替え
  if (w >= 768) {
    //初動
    if (!$(".js-swiper-detail").hasClass("swiper-initialized") && slideLength > 1) {
      //本体スライダー
      swiper = new Swiper(".js-swiper-detail", {
        direction: "vertical",
        mousewheel: true,
        slidesPerView: "auto",
        spaceBetween: 30,
        lazy: true,
        effect: "slide",
        pagination: {
          el: ".js-swiper-detail-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          }
        }
      });
    }
  } else {
    //PCからのチェンジ時（起動がある）
    if ($(".js-swiper-detail").hasClass("swiper-initialized")) {
      swiper.destroy();
    }
  }
});

//拡大スライダー
window.addEventListener("DOMContentLoaded", function () {
  var slideLength = $(".js-smartPhoto").length;
  var slide = $(".js-swiper-detail .swiper-slide");

  if (slideLength) {
    var mySmartPhoto = new SmartPhoto(".js-smartPhoto", {
      // resizeStyle: 'fit'
    });

    var list = $(".smartphoto-list li");

    mySmartPhoto.on("change", function () {
      var current = $(".smartphoto-list li.current");
      var currentNo = list.index(current);

      //本体スライダーが起動している時
      if ($(".js-swiper-detail").hasClass("swiper-initialized")) {
        swiper.slideTo(currentNo, 300, true);
      }
    });

    mySmartPhoto.on("open", function () {
      screenLock();
    });

    mySmartPhoto.on("close", function () {
      screenUnlock();

      //本体スライダーがない場合 = SP or PC 1枚時
      if (!$(".js-swiper-detail").hasClass("swiper-initialized") && slideLength > 1) {
        var current = $(".smartphoto-list li.current");
        var currentNo = list.index(current);
        var target = slide[currentNo];
        var position = target.offsetTop - 65;

        setTimeout(function () {
          window.scrollTo({
            top: position,
            behavior: "smooth"
          });
        }, 1);
      }
    });

    //マウス変更
    mySmartPhoto.on("zoomin", function () {
      var target = $(".smartphoto-list li.current .smartphoto-img-wrap");
      target[0].classList.add("smartphoto-img-zoom");
    });

    mySmartPhoto.on("zoomout", function () {
      var target = document.querySelectorAll(".smartphoto-img-wrap");
      target.forEach(function (el) {
        el.classList.remove("smartphoto-img-zoom");
      });
    });
  }
});
