const contents = {
  sound_on: { content_type: "TOP", content_name: "音声ON" },
  sound_off: { content_type: "TOP", content_name: "音声OFF" },
  top_museum: { content_type: "TOP", content_name: "ミュージアム" },
  top_election: { content_type: "TOP", content_name: "カプコン大選挙" },
  top_ceo: { content_type: "TOP", content_name: "会長の部屋" },
  top_coo: { content_type: "TOP", content_name: "社長の部屋" },
  top_retro_game: { content_type: "TOP", content_name: "レトロゲームズ" },
  top_news: { content_type: "TOP", content_name: "最新情報掲示板" },
  menu_museum: { content_type: "グローバルナビ", content_name: "ミュージアム" },
  menu_election: { content_type: "グローバルナビ", content_name: "カプコン大選挙" },
  menu_ceo: { content_type: "グローバルナビ", content_name: "会長の部屋" },
  menu_coo: { content_type: "グローバルナビ", content_name: "社長の部屋" },
  menu_retro_game: { content_type: "グローバルナビ", content_name: "レトロゲームズ" },
  menu_news: { content_type: "グローバルナビ", content_name: "最新情報掲示板" },
  menu_history: { content_type: "グローバルナビ", content_name: "ヒストリー" },
  menu_special: { content_type: "グローバルナビ", content_name: "" },
  menu_past_election: { content_type: "グローバルナビ", content_name: "終了した選挙" },
  museum_history: { content_type: "ミュージアムTOP", content_name: "ヒストリー" },
  museum_image: { content_type: "ミュージアム_", content_name: "画像" },
  museum_doc: { content_type: "ミュージアム_", content_name: "企画書・仕様書" },
  museum_video: { content_type: "ミュージアム_", content_name: "映像・音楽" },
  museum_other: { content_type: "ミュージアム_", content_name: "その他" },
  museum_title: { content_type: "タイトル一覧", content_name: "ゲームタイトル_" },
  retro_game_list: { content_type: "レトロゲームズ", content_name: "" },
  retro_game_cartridge: { content_type: "レトロゲームズ", content_name: "当時のカセット" },
  retro_game_manual: { content_type: "レトロゲームズ", content_name: "当時のトリセツ" }
};

$(window).on("load", function () {
  $("[data-kind]").on("click tap", function () {
    const element = $(this);
    createGTMData(element.data("kind"), element.data("type"), element.data("name"));
  });
  $("[data-election]").on("click tap", function () {
    const data = $(this).data("election");
    pushDataLayer(data);
  });
});

function createGTMData(kind, type, name) {
  let data = { event: "select_content", ...contents[kind] };
  if (type) {
    data["content_type"] += type;
  }
  if (name) {
    data["content_name"] += name;
  }
  pushDataLayer(data);
}

$(window).on("load", function () {
  const questionPv = $(".js-se-v1-question-pv[data-question-no]");
  if (questionPv.length) {
    pushDataLayer({
      event: "virtual_pv_elections",
      answer_number: `question${questionPv.data("question-no")}`
    });
  }
});

function pushDataLayer(data) {
  // console.log(data);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}
