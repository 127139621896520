var ua = navigator.userAgent;
var isIPad = ua.indexOf("iPad") > -1 || (ua.match(/Mac OS/) && "ontouchstart" in window);
var isIPhone = ua.indexOf("iPhone") > -1;
var isAndroid = ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1;
var isAndroidTab = ua.indexOf("Android") > -1;

//header
const $html = $("html");
const classLocked = "is-locked";

$(function () {
  headerMenu(); //header
  shareLink(); //SNSシェアリンク
  remodalCustom(); //remodalスクロールロック
  deviceSwitch(); // UA判定で要素非表示
  deviceSwitchLandscape(); // UA判定で横向き非対応メッセージ表示
  popup();
});
//function

$(window).on("scroll", function () {
  catchArea();
});

$(window).on("load", function () {
  loader(); //ローダー
  smoothScroll(); //スムーズスクロール
  catchArea(); //宣伝エリア
  bgmChange(); //BGM On/Off
});
//load

//==========
// 共通ローダー
//==========
function loader() {
  setTimeout(function () {
    $(window).scrollTop("0");
  }, 600);

  if ($(".js-loader").length) {
    //ローダーitem処理
    $(".js-loader-item").delay(600).fadeOut(600);

    //ローダー背景処理
    $(".js-loader")
      .delay(900)
      .animate(
        {
          height: "0"
        },
        900,
        "swing",
        function () {
          $("html").addClass("is-loaded");
          $(this).hide();
        }
      );
  } else {
    $("html").addClass("is-loaded");
  }
}

//==========
// remodal custom スクロールロック
//==========
function remodalCustom() {
  $(document).on("opened", ".remodal", function () {
    screenLock();
  });
  $(document).on("closed", ".remodal", function () {
    screenUnlock();
  });
}

//==========
// header
//==========
export function screenLock() {
  const top = $(window).scrollTop();

  if (!$html.hasClass(classLocked)) {
    $html
      .addClass(classLocked)
      .css({
        top: top * -1
      })
      .data("top", top);
  }
}

export function screenUnlock() {
  const top = $html.data("top") || 0;

  if ($html.hasClass(classLocked)) {
    $html.removeClass(classLocked).css({
      top: ""
    });
    $(window).scrollTop(top);
  }
}

function headerMenu() {
  const HEADER = $(".js-header");
  const BG_OVERLAY = $(".js-header-overlay");
  const GNAV_TARGET = $(".js-nav-target");
  const GNAV_SCROLL = $(".js-header-inner");
  const CLASS_OPEN = "is-open";

  GNAV_TARGET.on("click", function () {
    if (GNAV_TARGET.hasClass(CLASS_OPEN)) {
      $(this).removeClass(CLASS_OPEN);
      BG_OVERLAY.removeClass(CLASS_OPEN);
      HEADER.stop().removeClass(CLASS_OPEN);
      GNAV_SCROLL.scrollTop(0);
      screenUnlock();
    } else {
      $(this).addClass(CLASS_OPEN);
      HEADER.stop().addClass(CLASS_OPEN);
      BG_OVERLAY.addClass(CLASS_OPEN);
      screenLock();
    }
  });

  BG_OVERLAY.on("click", function () {
    GNAV_TARGET.removeClass(CLASS_OPEN);
    BG_OVERLAY.removeClass(CLASS_OPEN);
    HEADER.stop().removeClass(CLASS_OPEN);
    GNAV_SCROLL.scrollTop(0);
    screenUnlock();
  });
}

//==========
//デバイスにより切り替え
//==========

function deviceSwitch() {
  const ONLY_SP = ".js-only-sp";
  const ONLY_TB = ".js-only-tb";
  const ONLY_PC = ".js-only-pc";

  //SP
  if (isIPhone || isAndroid) {
    $(ONLY_TB).not(ONLY_SP).hide();
    $(ONLY_PC).not(ONLY_SP).hide();

    //TB
  } else if (isIPad || isAndroidTab) {
    $(ONLY_SP).not(ONLY_TB).hide();
    $(ONLY_PC).not(ONLY_TB).hide();

    //PC
  } else {
    $(ONLY_SP).not(ONLY_PC).hide();
    $(ONLY_TB).not(ONLY_PC).hide();
  }
}

//==========
//デバイスにより横向き非対応メッセージ切り替え
//==========
function deviceSwitchLandscape() {
  const message = ".js-landscape";
  if (isIPhone || isAndroid) {
    //SP
    $(message).addClass("is-active");
  } else {
    //TB・PC
    $(message).removeClass("is-active");
  }
}

//==========
//SNSシェア
//==========
function shareLink() {
  const pageTitle = document.title;
  const uaSP = isIPhone || isIPad || isAndroid || isAndroidTab ? true : false; //lineアプリ対応

  /* ----------
    data属性あり：それを挿入
    data属性なし：現在のページURLとタイトルを挿入
  ---------- */

  //Twitterシェア
  $(".js-share-tw").click(function (e) {
    e.preventDefault();
    pushShareDataLayer("Twitter");
    let _this = $(this);
    let share = shareInfo(_this, pageTitle);
    const url =
      "//twitter.com/intent/tweet?text=" +
      share.title + // シェア文言
      "&url=" +
      share.link + // シェアするURL
      "&hashtags=" +
      share.tag; // ハッシュタグ
    window.open(url, "_blank");
  });

  //Facebookシェア
  $(".js-share-fb").click(function (e) {
    e.preventDefault();
    pushShareDataLayer("Facebook");
    let _this = $(this);
    let share = shareInfo(_this, pageTitle);
    const url = "//www.facebook.com/sharer/sharer.php?u=" + share.link + "&text=" + share.title;
    window.open(url, "_blank");
  });

  //LINEシェア
  $(".js-share-line").click(function (e) {
    e.preventDefault();
    pushShareDataLayer("LINE");
    let _this = $(this);
    let share = shareInfo(_this, pageTitle);

    //スマホ・タブレットはLINE URLスキーム、PCはLINE Social Pluginsに切り替え
    const url = uaSP
      ? "//line.me/R/msg/text/?" + share.title + " " + share.link
      : "//social-plugins.line.me/lineit/share?url=" + share.link + "&text=" + share.title;

    window.open(url, "_blank");
  });

  //シェアURLコピー
  $(".js-share-copy").click(function (e) {
    e.preventDefault();
    pushShareDataLayer("URL");
    let _this = $(this);
    let share = shareInfo(_this, pageTitle);
    const url = decodeURI(share.title) + " " + share.link;

    // クリップボードにコピー
    navigator.clipboard.writeText(url);
    // alert("（SSH確認用）" + url);

    // フラッシュメッセージ表示
    $(".js-share-copy-message").fadeIn("2000", function () {
      $(this).delay(1500).fadeOut("3000");
    });
  });

  //Weiboシェア
  $(".js-share-weibo").click(function (e) {
    e.preventDefault();
    pushShareDataLayer("Weibo");
    let _this = $(this);
    let share = shareInfo(_this, pageTitle);
    const url =
      "//service.weibo.com/share/share.php?url=" +
      share.link + // シェアするURL
      "&title=" +
      share.title; // シェア文言シェアするURL
    window.open(url, "_blank");
  });
}

//個別シェア情報のチェック
function shareInfo(t, page) {
  let _this = t;
  let pageTitle = encodeURI(page);
  let title = _this.data("share-title") ? _this.data("share-title") : pageTitle;
  let link = _this.data("share-url") ? _this.data("share-url") : location.href;
  let tag = _this.data("hashtag");
  return {
    title: title,
    link: link,
    tag: tag
  };
}

//シェア時のGTM処理
function pushShareDataLayer(method) {
  const data = {
    event: "share",
    share_method: method
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}

//==========
// ポップアップ （メディアデバイスお知らせ）
//==========
function popup() {
  var popup = $(".js-popup");
  var popupClose = popup.find(".js-popup-close");

  popupClose.on("click", function () {
    popup.removeClass("is-opened");
  });
}

//==========
// catchエリア 表示
//==========
function catchArea() {
  if ($(".js-catch").length) {
    var scrollTop = $(window).scrollTop(),
      winHeight = $(window).height(),
      contents = $(".js-catch").parent(), //直近の親の高さから逆算
      stopPoint = Math.floor(contents.offset().top + contents.outerHeight(true)),
      target = $(".js-catch-target");

    //ページ下部に到達したら
    if (scrollTop > stopPoint - winHeight && scrollTop > 0) {
      //スクロールしていない時は消さない
      target.addClass("is-hidden");
    } else {
      target.removeClass("is-hidden");
    }
  }
}

//==========
//スムーズスクロール
//==========
function smoothScroll() {
  $.easing.quart = function (x, t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  };

  $(".js-scroll").click(function () {
    var speed = 1200,
      href = $(this).attr("href"),
      target = $(href == "#" || href == "" ? "html" : href),
      position = target.offset().top;

    $("html, body").animate(
      {
        scrollTop: position
      },
      speed,
      "quart"
    );

    return false;
  });
}

//==========
// BGM On/Off 切り替え
//==========
const bgmNum = Date.now() % 3;

function bgmChange() {
  if ($(".bgm").length) {
    $(".js-btn-play[data-sound=on]").on("click", function () {
      $("#sound").prop("checked", false);
      $("#sound").change();
    });

    $("#sound").on("change", function () {
      if ($(this).prop("checked")) {
        $(".bgm").each(function () {
          this.pause();
        });
      } else {
        $(".bgm")[bgmNum].play();
      }
    });
  }
}
