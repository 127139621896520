import Swiper from "swiper/bundle";
import { gsap, Power2, ScrollTrigger } from "gsap/dist/all";

$(function () {
  //==========
  // 汎用フェードイン アニメーション
  //==========
  //左から順に下からフェードイン
  gsap.set(".js-goods-factory-fade-in", {
    y: 100,
    opacity: 0
  });

  ScrollTrigger.batch(".js-goods-factory-fade-in", {
    batchMax: 3,
    onEnter: (batch) =>
      gsap.to(batch, {
        y: 0,
        opacity: 1,
        duration: 0.35,
        ease: Power2.easeOut,
        stagger: {
          each: 0.3,
          from: "start"
        }
      }),
    start: "top 80%"
  });

  ///トップ スライダー
  new Swiper(".js-goods-factory-swiper", {
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 30,
    speed: 6000,
    allowTouchMove: false,
    breakpoints: {
      768: {
        spaceBetween: 40,
        speed: 12000
      }
    },
    autoplay: {
      delay: 0
    }
  });

  //==========
  // トップ フェードインアニメーション
  //==========
  gsap.utils.toArray(".js-goods-factory-section").forEach((el) => {
    gsap.from(el, {
      y: 100,
      opacity: 0,
      duration: 0.35,
      scrollTrigger: {
        start: function () {
          // 画面幅によって異なる値を返す
          if (window.innerWidth < 768) {
            return "top 80%";
          } else {
            return "top 60%";
          }
        },
        trigger: el
      }
    });
  });

  //==========
  // 商品詳細 画像スライダー
  //==========
  //サムネイル
  var sliderThumbnail = new Swiper(".js-goods-factory-detail-thumbnail", {
    slidesPerView: "auto",
    spaceBetween: 8,
    breakpoints: {
      768: {
        spaceBetween: 10
      }
    }
  });

  //スライダー
  new Swiper(".js-goods-factory-image-swiper", {
    mousewheel: true,
    lazy: true,
    delay: 0,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    keyboard: {
      enabled: true
    },
    a11y: {
      containerMessage: "商品画像のスライダー",
      slideLabelMessage: "{{index}}枚目の商品画像"
    },
    navigation: {
      nextEl: ".js-goods-factory-image-swiper-next",
      prevEl: ".js-goods-factory-image-swiper-prev"
    },
    pagination: {
      el: ".js-goods-factory-image-swiper-pagination",
      type: "fraction"
    },
    thumbs: {
      swiper: sliderThumbnail
    }
  });
});
