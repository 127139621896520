import { Controller } from "@hotwired/stimulus";
import { isValid, parse, parseISO } from "date-fns";

export default class extends Controller {
  static targets = ["month", "day", "year"];
  dateOfBirthKey = "museum-date-of-birth";
  modal = $("[data-remodal-id=modalAge]").remodal({
    hashTracking: false,
    closeOnEscape: false,
    closeOnOutsideClick: false,
    modifier: "remodal-museum-age"
  });

  connect() {
    const dateOfBirth = sessionStorage.getItem(this.dateOfBirthKey);
    if (dateOfBirth === null) {
      $(".js-museums-age-under").hide();
      this.modal.open();
    } else {
      const date = parseISO(dateOfBirth);
      if (isValid(date)) {
        if (this.isUnder17(date)) {
          $(".js-museums-age-confirm").hide();
          this.modal.open();
        }
      } else {
        sessionStorage.removeItem(this.dateOfBirthKey);
        $(".js-museums-age-under").hide();
        this.modal.open();
      }
    }
  }

  submit() {
    const month = this.monthTarget.value;
    const day = this.dayTarget.value;
    const year = this.yearTarget.value;
    const date = parse(`${year}-${month}-${day}`, "yyyy-MM-dd", new Date());
    if (isValid(date)) {
      sessionStorage.setItem(this.dateOfBirthKey, date.toISOString());
      if (this.isUnder17(date)) {
        $(".js-museums-age-confirm").hide();
        $(".js-museums-age-under").show();
      } else {
        this.modal.close();
      }
    } else {
      $(".js-museums-age-label").addClass("remodal-text--error");
      $(this.monthTarget).addClass("is-error");
      $(this.dayTarget).addClass("is-error");
      $(this.yearTarget).addClass("is-error");
    }
  }

  isUnder17(dateOfBirth) {
    const now = Date.now();
    const monthDiff = now - dateOfBirth.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    const age = Math.abs(year - 1970);
    return age < 17 ? true : false;
  }
}
