import Swiper from "swiper/bundle";

$(function () {
  //==========
  //スライダー
  //==========
  var swiper = new Swiper(".js-swiper", {
    hashNavigation: {
      replaceState: true
    },
    forceToAxis: true,
    lazy: true,
    centeredSlides: true,
    slidesPerView: "auto",
    speed: 600,
    spaceBetween: 20,
    mousewheel: true,
    breakpoints: {
      768: {
        spaceBetween: 200
      }
    },
    effect: "coverflow",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false
    },
    pagination: {
      el: ".js-swiper-pagination",
      type: "fraction"
    },
    navigation: {
      nextEl: ".js-swiper-button-next",
      prevEl: ".js-swiper-button-prev"
    },
    on: {
      //起動時、矢印をチェック
      init: function () {
        if (this.isBeginning) {
          $(".js-swiper-control-first").addClass("is-active");
        } else if (this.isEnd) {
          $(".js-swiper-control-last").addClass("is-active");
        }

        //次のスライドのloading属性を削除
        $(".swiper-slide-active").next().find("img").attr("loading", "");

        //カテゴリナビをチェック
        let active = $(".swiper-slide-active").data("category");
        $('.js-slider-nav[data-category="' + active + '"]').addClass("is-current");
      }
    }
  });

  let sliderCate = $(".js-slider-nav");

  //スライドが変わるときの処理
  swiper.on("slideChange", function () {
    //矢印をチェック
    $(".js-swiper-control-first, .js-swiper-control-last").removeClass("is-active");
    if (swiper.isBeginning) {
      $(".js-swiper-control-first").addClass("is-active");
    } else if (swiper.isEnd) {
      $(".js-swiper-control-last").addClass("is-active");
    }

    //次のスライドのloading属性を削除
    $(".swiper-slide-next").next().find("img").attr("loading", "");
  });

  //スライドが変わったら、ナビカテゴリをチェック
  swiper.on("slideChangeTransitionStart", function () {
    let active = $(".swiper-slide-active").data("category");
    sliderCate.removeClass("is-current");
    $('.js-slider-nav[data-category="' + active + '"]').addClass("is-current");
  });

  //ナビゲーションで移動
  sliderCate.on("click", function () {
    const category = $(this).data("category");
    const num = $(`.js-swiper .swiper-slide[data-category=${category}]:first`).index();
    swiper.slideTo(num, 2000);
    //初回のみクルクル回るのを矯正する
    if ($(".swiper-slide").hasClass("is-loaded") == false) {
      $(".swiper-slide").addClass("is-skip");
      $(".swiper-slide").eq(num).find("img").attr("loading", "");
      setTimeout(function () {
        $(".swiper-slide").removeClass("is-skip").addClass("is-loaded");
      }, 1350);
    }
  });

  //==========
  //映像用パラパラ
  //==========
  var interval = 2000; // 切り替わりの間隔（ミリ秒）
  var fade_speed = 1000; // フェード処理の早さ（ミリ秒）
  $(".js-museum-list-movie-art img").hide();
  $(".js-museum-list-movie-art img:first").addClass("is-active").show();

  var changeImage = function () {
    var $active = $(".js-museum-list-movie-art img.is-active");
    var $next = $active.next("img").length ? $active.next("img") : $(".js-museum-list-movie-art img:first");

    $active.removeClass("is-active");
    $next.addClass("is-active").fadeIn(fade_speed, function () {
      $active.hide();
    });
  };
  setInterval(changeImage, interval);
});
//function
