$(function () {
  //プライバシーポリシー同意でボタン活性化
  $(".js-winner-checkbox").on("change", function () {
    if ($(this).prop("checked")) {
      $(".js-winner-button").removeClass("is-disabled");
    } else {
      $(".js-winner-button").addClass("is-disabled");
    }
  });
});
