import { screenLock, screenUnlock } from "./common";
import "select2/dist/js/select2.full.js";
import "select2/dist/js/i18n/ja";
import "select2/dist/js/i18n/en";
import "select2/dist/js/i18n/fr";
import "select2/dist/js/i18n/it";
import "select2/dist/js/i18n/de";
import "select2/dist/js/i18n/es";
import "select2/dist/js/i18n/pt-BR";
import "select2/dist/js/i18n/zh-CN";
import "select2/dist/js/i18n/zh-TW";
import "select2/dist/js/i18n/ko";
import { gsap, Back, Power1, Power4 } from "gsap/dist/all";

gsap.registerPlugin(Back, Power1, Power4);

$(window).on("load", function () {
  //==========
  //ローディング アニメーション
  //==========
  if ($(".js-question").length) {
    //導入アニメーションがある時は発火させない
    if (!$(".js-question-introduction").length) {
      screenLock();
    }
    setTimeout(function () {
      $(".js-question").addClass("is-complete");
      setTimeout(function () {
        //導入アニメーションがある時は発火させない
        if (!$(".js-question-introduction").length) {
          screenUnlock();
        }
      }, 600);
    }, 600);
  }
});

//読み込み、リサイズ時
$(window).on("load resize", function () {
  var windowWidth = $(window).width();

  //==========
  //SP時 7問目以降のナビゲーションのスクロール位置を右寄せに指定
  //==========
  if (windowWidth < 768) {
    var navigation = $(".js-question-nav-end");
    if (navigation.length > 0) {
      navigation.scrollLeft(navigation[0].scrollWidth - navigation[0].clientWidth);
    }
  }
});

//読み込み時
$(function () {
  //==========
  //gsap 導入アニメーション
  //==========
  if ($(".js-question-introduction").length) {
    const IntroAnimation = gsap.timeline({
      onStart: function () {
        //アニメーション開始前
        screenLock();
      },
      onComplete: function () {
        //アニメーション開始後
        $(".js-question-introduction").addClass("is-hide");
        screenUnlock();
      }
    });
    IntroAnimation.to(".js-introduction-title", { scale: 1, duration: 0.8, ease: Back.easeOut }, "<1");
    IntroAnimation.to(".js-introduction-balloon", { y: "-50%", duration: 5, ease: "none" }, "<");
    IntroAnimation.to(".js-introduction-truck", { x: "100%", duration: 2, ease: "none" }, "<1");
    IntroAnimation.to(".js-introduction-slide01", { opacity: 0, duration: 2, ease: Power4.easeOut }, "<2.2");
    IntroAnimation.to(".js-introduction-slide03", { opacity: 1, duration: 0.3, ease: Power4.easeOut }, "<1.5");
    IntroAnimation.to(".js-introduction-slide04", { opacity: 1, duration: 0.3, ease: Power4.easeOut }, "<0.5");
    IntroAnimation.to(".js-introduction-slide05", { opacity: 1, duration: 0.3, ease: Power4.easeOut }, "<0.5");
    IntroAnimation.to(".js-introduction-slide05", { scale: 1.5, duration: 2.5, ease: Power1.easeOut }, "<0.5");
    IntroAnimation.to(".js-question-introduction", { opacity: 0, duration: 1.5, ease: Power4.easeOut }, "-=1");
    //アニメーションを再生
    IntroAnimation.play();
  }

  //==========
  //select2
  //==========
  if ($(".js-question-select").length) {
    $(".js-question-select")
      .select2({
        language: document.querySelector("html").lang,
        minimumInputLength: 2,
        maximumInputLength: 30,
        dropdownParent: $(".js-question-select-box"),
        ajax: {
          dataType: "json",
          delay: 300,
          data: (params) => {
            return {
              q: { term: params.term },
              page: params.page
            };
          },
          processResults: (data, params) => {
            params.page = params.page || 1;
            const more = params.page * 25 < data.total;
            return { results: data.choices, pagination: { more: more } };
          }
        },
        templateResult: (data) => {
          if (data.loading) {
            return data.text;
          } else {
            const result = $("<div/>").attr("class", "question-select__item");
            result.append($("<img/>").attr("class", "question-select__image").attr("src", data.image));
            result.append($("<span/>").attr("class", "question-select__text").text(data.text));
            return result;
          }
        }
      })
      .on("select2:open", function (event) {
        document.querySelector(`.select2-search__field[aria-controls='select2-${event.target.id}-results']`).focus();
      })
      .on("select2:select", function (event) {
        scrollToBottom();
        var data = event.params.data;
        var selectedImage = $(this).closest(".js-question").find(".js-selected-image");
        //選択した画像をボックスに表示
        selectedImage.find("img").attr("src", data.image).attr("alt", data.text);
      });
  }
});

function scrollToBottom() {
  $(".js-question-section").animate({ scrollTop: $(".js-question-section").height() }, 500, "swing");
}

$(document).on("change load", ".js-question-present-checkbox, .js-question-present-radio", function () {
  let checkboxAll = $(".js-question-present-checkbox").length;
  let checkboxChecked = $(".js-question-present-checkbox:checked").length;
  let declineChecked = $("#answer_present_decline:checked").length;

  if (declineChecked === 1 || checkboxAll === checkboxChecked) {
    $(".js-question-present-button").removeClass("is-disabled");
  } else {
    $(".js-question-present-button").addClass("is-disabled");
  }
});

$(window).on("load", function () {
  let declineChecked = $("#answer_present_decline:checked").length;

  if (declineChecked === 1) {
    $(".js-question-present-button").removeClass("is-disabled");
  }
});
