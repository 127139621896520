import Swiper from "swiper/bundle";

var swiperInfo = null;

$(window).on("load", function () {
  /* ------------------------------
  ゲームリスト並び替え
  ------------------------------ */
  $("#js-game-list").mixItUp();

  /* ------------------------------
  スライダー screen
  ------------------------------ */
  var swiperScreen = new Swiper(".js-swiper-screen", {
    speed: "1200",
    effect: "flip",
    flipEffect: {
      slideShadows: false
    }
  });

  // スクリーンが移動しても連動
  swiperScreen.on("slideChange", () => {
    swiperInfo.slideToLoop(swiperScreen.realIndex);
  });

  swiperScreen.on("slideChangeTransitionEnd", () => {
    playMuteYoutube();
  });

  /* ------------------------------
  スライダー info
  ------------------------------ */
  swiperInfo = new Swiper(".js-swiper-info", {
    speed: "600",
    // mousewheel: true,
    spaceBetween: 30,
    autoHeight: true,
    navigation: {
      nextEl: ".js-swiper-button-next",
      prevEl: ".js-swiper-button-prev"
    }
  });

  // スクリーンも連動
  swiperInfo.on("slideChange", () => {
    swiperScreen.slideToLoop(swiperInfo.realIndex);
  });

  /* ------------------------------
  日英切り替え
  ------------------------------ */
  var NAV = $(".js-gameLang-nav");
  var NAV_CURRENT = $(".js-gameLang-nav.is-current");

  //初期表示
  NAV_CURRENT.each(function () {
    langChange($(this));
  });

  //click時
  NAV.on("click", function (e) {
    e.preventDefault();
    langChange($(this));
  });
});

function langChange(t) {
  let CLICK = t;
  let PARENT = CLICK.closest(".js-gameLang-info");
  let INDEX = $(".js-gameLang-info").index(PARENT);
  let PARENT_SCREEN = $(".js-gameLang-screen").eq(INDEX);
  let CL_LANG = CLICK.data("lang");

  setTimeout(function () {
    swiperInfo.updateAutoHeight();
  }, 10);

  // PARENT.attr("lang-lang", CL_LANG);

  PARENT.find(".js-gameLang-nav").removeClass("is-current");
  CLICK.addClass("is-current");

  //コンソール入れ替え
  PARENT.find(".js-gameLang-console-target").removeClass("is-active");
  PARENT.find('.js-gameLang-console-target[data-lang="' + CL_LANG + '"]').addClass("is-active");

  //info入れ替え
  PARENT.find(".js-gameLang-info-target").removeClass("is-active");
  PARENT.find('.js-gameLang-info-target[data-lang="' + CL_LANG + '"]').addClass("is-active");

  //screen入れ替え
  PARENT_SCREEN.find(".js-gameLang-screen-target").removeClass("is-active");
  PARENT_SCREEN.find('.js-gameLang-screen-target[data-lang="' + CL_LANG + '"]').addClass("is-active");

  playMuteYoutube();
}

//==========
//YouTube mute 再生
//==========
function playMuteYoutube() {
  document.querySelectorAll(".js-gameLang-screen-target iframe").forEach(function (item) {
    item.contentWindow.postMessage('{"event":"command", "func":"stopVideo"}', "*");
  });

  const ytiframe = document.querySelector(".swiper-slide-active .js-gameLang-screen-target.is-active iframe");
  if (ytiframe) {
    ytiframe.contentWindow.postMessage('{"event":"command", "func":"playVideo"}', "*");
  }
}
