import { screenLock, screenUnlock } from "./common";

$(function () {
  var windowWidth = $(window).width();

  gameSound();
  sideNav();

  $(window).on("resize", function () {
    // load時のwindow幅が変更された時
    if (windowWidth != $(window).width()) {
      if ($(".js-side-nav-content").hasClass("is-open")) {
        sideNavClose();
      }
    }
  });
});

// ゲームプレイ画面のサウンドボタン
function gameSound() {
  var sound = $(".js-sound");
  var soundButton = sound.find(".js-sound-button");
  var soundRange = sound.find(".js-sound-range");

  //音量バーの挙動によって音声ON/OFF自動切り替え
  soundRange.on("change", function () {
    if ($(this).val() == "0") {
      soundButton.prop("checked", true);
    } else {
      soundButton.prop("checked", false);
    }
  });
}

function sideNav() {
  $(".js-side-nav-tab").on("click", function (e) {
    e.preventDefault();

    var target = $(this).attr("href"), //サイドナビ中身
      windowWidth = $(window).width(); //画面幅取得

    if ($(this).hasClass("is-current")) {
      sideNavClose();
    } else {
      //タブのクラスつけ外し
      $(".js-side-nav-tab").removeClass("is-current");
      $(this).addClass("is-current");

      //サイドナビ外枠表示
      $(".js-side-nav-content").addClass("is-open");

      //サイドナビ中身表示
      $(".js-side-nav-panel").hide();
      $(target).show();

      //SP時 スクロール禁止
      if (windowWidth < 768) {
        screenLock();
      } else {
        $(".js-game-detail-main").css("margin-right", "340px");
      }

      //閉じるボタン表示
      $(".js-side-nav-close").addClass("is-show");
    }
  });

  //閉じるボタンクリック時
  $(".js-side-nav-close").on("click", function () {
    sideNavClose();
  });
}

//サイドナビ閉じる挙動
function sideNavClose() {
  var windowWidth = $(window).width();

  //タブのカレントクラス外す
  $(".js-side-nav-tab").removeClass("is-current");

  //サイドナビ非表示
  $(".js-side-nav-content").removeClass("is-open");

  $(".js-game-detail-main").css("margin-right", "0");

  //閉じるボタン非表示
  $(".js-side-nav-close").removeClass("is-show");

  //SP時 スクロール禁止解除
  if (windowWidth < 768) {
    screenUnlock();
  }
}
